import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import "./index.css";
import "@fontsource/roboto";

import firebase from "firebase/app";

firebase.initializeApp({
  apiKey: "AIzaSyAKVcf3BgrbckxdtN6H-NIYv4Bhlb94OlQ",
  authDomain: "teqni-us.firebaseapp.com",
  projectId: "teqni-us",
  storageBucket: "teqni-us.appspot.com",
  messagingSenderId: "486817731932",
  appId: "1:486817731932:web:d6a5741f17825cc5479d99",
  measurementId: "G-5TFM27D84H",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
