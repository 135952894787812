import React, { useState, useMemo, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@material-ui/core";

import { RouteComponentProps } from "react-router-dom";

import firebase from "firebase/app";
import "firebase/firestore";

type Params = {
  alias: string;
};

type Status = "loading" | "invalid" | "success";

const AliasRedirect = ({ match }: RouteComponentProps<Params>) => {
  const db = useMemo(() => {
    return firebase.firestore();
  }, []);

  const [status, setStatus] = useState<Status>("loading");

  const alias = match.params.alias;
  useEffect(() => {
    async function get(alias: string): Promise<void> {
      if (alias === undefined) {
        setStatus("invalid");
        window.location.href = "https://teqnius.com";
      } else {
        const query = await db
          .collection("urls")
          .where("alias", "==", alias)
          .get();
        if (query.empty) {
          setStatus("invalid");
          window.location.href = "https://teqnius.com";
        } else {
          window.location = query.docs[0].data().url;
          setStatus("success");
        }
      }
    }
    get(alias);
  }, [alias, db]);

  return (
    <div className="center">
      <CircularProgress />
    </div>
  );
};

export default AliasRedirect;
